<template>
  <el-dialog
    :title="$l('maintWorkOrder.maintItem','保养项目')"
    :visible.sync="dialogVisible"
    width="1500px"
    top="2vh"
    class="adaptationModal"
    append-to-body>
    <el-table
      v-loading="loading"
      :data="maintItemList"
      stripe
      border>
      <el-table-column type="index" :label="$l('maintWorkOrder.no','序号')" width="60" align="center"></el-table-column>
      <el-table-column prop="itemName" :label="$l('maintWorkOrder.itemName','保养项名称')"></el-table-column>
      <el-table-column prop="maintContent" :label="$l('maintWorkOrder.maintContent','保养内容')"></el-table-column>
      <el-table-column prop="maintRequire" :label="$l('maintWorkOrder.maintRequire','保养要求')"></el-table-column>
      <el-table-column prop="maintArea" :label="$l('maintWorkOrder.maintArea','保养区域')" width="100px">
        <template v-slot="scope">
          <span v-if="scope.row.maintArea === '机房'">
            {{$l("maintWorkOrder.maintenanceArea.machineRoom","机房")}}
          </span>
          <span v-else-if="scope.row.maintArea === '轿厢'">
            {{$l("maintWorkOrder.maintenanceArea.bridge","轿厢")}}
          </span>
          <span v-else-if="scope.row.maintArea === '层站'">
            {{$l("maintWorkOrder.maintenanceArea.layer","层站")}}
          </span>
          <span v-else-if="scope.row.maintArea === '井道及底坑'">
            {{$l("maintWorkOrder.maintenanceArea.wellRoadAndTheBottomPit","井道及底坑")}}
          </span>
          <span v-else-if="scope.row.maintArea === '扶梯'">
            {{$l("maintWorkOrder.maintenanceArea.escalator","扶梯")}}
          </span>
          <span v-else-if="scope.row.maintArea === '其他'">
            {{$l("maintWorkOrder.maintenanceArea.other","其他")}}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="remark" :label="$l('maintWorkOrder.remark','备注')"></el-table-column>
      <el-table-column prop="isDefault" :label="$l('maintWorkOrder.isDefault','默认')" align="center" width="80">
        <template v-slot="scope">
          <el-tag v-if="scope.row.isDefault === 1" type="success">
            {{$l("common.yes","是")}}
          </el-tag>
          <el-tag v-else-if="scope.row.isDefault === 0" type="info">
            {{$l("common.no","否")}}
          </el-tag>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
  export default {
    data() {
      return {
        loading: true,
        dialogVisible: false,
        maintItemList: [],
      };
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        this.getData(id);
      },
      getData(id) {
        this.$api.getById("maintType", id).then(res => {
          this.getMaintItemList(res.data.maintItemIdList);
          this.loading = false;
        }).catch((error) => {
          if (error.response) {
            this.loading = false;
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      getMaintItemList(idList) {
        let param = {
          idList: idList.toString(),
        };
        this.$api.getData("maintItem/type", param).then(res => {
          this.maintItemList = res.data;
        }).catch(error => {
          if (error.response) {
            this.$message.error(this.$l("maintWorkOrder.tip.getMaintTypeItemError", "获取保养类型项目失败") + "," + error.response.data.message);
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>

</style>