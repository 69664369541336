<template>
  <el-dialog
    :title="$l('maintWorkOrder.maintPlan','保养计划')"
    :visible.sync="dialogVisible"
    width="1100px"
    top="2vh">
    <el-table
      v-loading="loading"
      :data="maintDateList"
      stripe
      border>
      <el-table-column type="index" :label="$l('maintWorkOrder.no','序号')" width="45px" align="center"></el-table-column>
      <el-table-column prop="maintDate" :label="$l('maintWorkOrder.maintDate','保养日期')"></el-table-column>
      <el-table-column prop="maintTypeName" :label="$l('maintWorkOrder.maintTypeName','保养类型名称')"></el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
  export default {
    data() {
      return {
        loading: true,
        dialogVisible: false,
        maintDateList: [],
      };
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        this.getData(id);
      },
      getData(id) {
        this.$api.getById("maintPlan/elevator", id).then(res => {
          this.maintDateList = res.data;
          this.loading = false;
        }).catch((error) => {
          if (error.response) {
            this.loading = false;
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>

</style>