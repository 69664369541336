<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="1200px"
    class="adaptationModal"
    @close="onDialogClose">
    <el-container v-loading="contentLoading" style="height: 500px">
      <el-aside width="350px">
        <el-card style="width: 300px;" shadow="never">
          <div slot="header" class="clearfix" style="font-size: 13px">
            <span>{{$l('maintWorkOrder.plan.elevator','电梯信息')}}</span>
          </div>
          <el-tag
            v-for="tag in elevatorList"
            :key="tag.no"
            size="mini"
            style="margin-right: 10px">
            {{tag.no}}
          </el-tag>
        </el-card>
        <el-form
          ref="formValidate"
          :rules="ruleValidate"
          :label-width="$l('120px','100px')"
          :model="maintPlanTemp"
          style="margin-top: 30px"
          size="mini">
          <el-form-item :label="$l('maintWorkOrder.plan.planStartTime','计划开始日期')" prop="planStartDate">
            <el-date-picker
              v-model="maintPlanTemp.planStartDate"
              type="date"
              style="width: 200px"
              :placeholder="$t('yearCheck.date')"
              value-format="yyyy-MM-dd"
              :disabled="maintDateDisabled"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
          <el-form-item :label="$l('maintWorkOrder.plan.planEndTime','计划结束日期')" prop="planEndDate">
            <el-date-picker
              v-model="maintPlanTemp.planEndDate"
              type="date"
              style="width: 200px"
              :placeholder="$t('yearCheck.date')"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
          <el-form-item :label="$l('maintWorkOrder.plan.maintCycle','保养周期')" prop="maintCycle">
            <el-input
              v-model.number="maintPlanTemp.maintCycle"
              style="width: 200px"
              :disabled="maintCycleDisabled"
              :placeholder="$t('common.pleaseEnter')">
            </el-input>
          </el-form-item>
          <div style="text-align: center; margin-top: 30px; width: 300px">
            <el-button type="primary" :loading="submitLoading" @click="handleGeneratePlan">{{$l('maintWorkOrder.generatePlan','生成计划')}}</el-button>
          </div>
        </el-form>
      </el-aside>
      <el-main style="border: 1px solid #eee">
        <el-table
          v-loading="contentLoading"
          :data="maintPlanList"
          border>
          <el-table-column type="index" :label="$l('maintWorkOrder.no','序号')" width="45px" align="center"></el-table-column>
          <el-table-column prop="maintDate" :label="$l('maintWorkOrder.maintDate','保养日期')">
            <template v-slot="scope">
              <el-date-picker
                v-model="scope.row.maintDate"
                type="date"
                :clearable="false"
                :placeholder="$t('yearCheck.date')"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                :disabled="scope.row.editDisabled"
                @change="handleDateChange(scope)">
              </el-date-picker>
            </template>
          </el-table-column>
          <el-table-column prop="maintTypeName" :label="$l('maintWorkOrder.maintTypeName','保养类型名称')">
            <template v-slot="scope">
              <el-select
                v-model="scope.row.maintType"
                value-key="id"
                :placeholder="$t('common.pleaseSelect')"
                :disabled="scope.row.editDisabled"
                clearable>
                <el-option
                  v-for="item in maintTypeList"
                  :key="item.id"
                  :label="item.maintTypeName"
                  :value="item.id"
                  @click.native="handleMaintType(scope, item)">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button
        type="primary"
        :loading="submitLoading"
        :disabled="saveDisabled"
        @click="handleSubmit">{{$t("common.save")}}</el-button>
    </span>
    <maint-type-item ref="listMaintTypeItem"></maint-type-item>
  </el-dialog>
</template>
<script>
  import MaintTypeItem from "@/views/newMaintWorkOrder/maintSetting/maintTypeItem";

  const moduleName = "maintPlan";
  export default {
    components: {MaintTypeItem},
    data() {
      return {
        contentLoading: false,
        dialogVisible: false,
        submitLoading: false,
        saveDisabled: false,
        maintDateDisabled: false,
        maintCycleDisabled: false,
        havePlan: false,
        maintTypeList: [],
        maintPlanList: [],
        maintPlanAddList: [],
        elevatorList:[],
        halfMonth: "",
        halfYear: "",
        month: "",
        year: "",
        maintPlanTemp: {
          planStartDate: "",
          planEndDate: "",
          maintCycle: "",
        },
        ruleValidate: {
          planStartDate: [
            {required: true, message: this.$l("maintWorkOrder.tip.planStartDate","计划开始日期不能为空"), trigger: "blur"},
          ],
          planEndDate: [
            {required: true, message: this.$l("maintWorkOrder.tip.planEndDate","计划结束日期不能为空"), trigger: "blur"},
          ],
          maintCycle: [
            {required: true, type: "number", min: 1, message: this.$l("maintWorkOrder.tip.maintCycle","请输入一个大于0的数字"), trigger: "blur"},
          ],
        },
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() <= Date.now() - 3600 * 1000 * 24;
          },
        },
      };
    },
    computed: {
      title() {
        return (!this.havePlan? this.$t("common.add") : this.$t("common.edit")) + " " + this.$l("route.maintPlan", "保养计划");
      },
    },
    methods: {
      open(list, havePlan) {
        this.havePlan = havePlan;
        this.dialogVisible = true;
        this.elevatorList = list;
        if (list[0].maintPlan && list.length === 1) {
          this.getData(list[0].id);
        }
        this.getMaintTypeList();
      },
      getData(id) {
        this.contentLoading = true;
        this.saveDisabled = true;
        this.$api.getById(moduleName + "/elevator", id).then(res => {
          this.maintPlanList = res.data;
          this.maintPlanList.forEach(item => {
            item["editDisabled"] = true;
          });
          if (this.maintPlanList.length >= 1) {
            this.maintPlanTemp.planStartDate = this.elevatorList[0].planStartTime;
            this.maintPlanTemp.planEndDate = this.elevatorList[0].planEndTime;
            this.maintDateDisabled = true;
            if (this.maintPlanList.length >= 2) {
              this.maintPlanTemp.maintCycle = (Date.parse(this.maintPlanList[1].maintDate) - Date.parse(this.maintPlanList[0].maintDate)) / (24*3600*1000);
              this.maintCycleDisabled = true;
            }
          }
          this.contentLoading = false;
          this.saveDisabled = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      getMaintTypeList() {
        this.$api.getData("maintType/all", null).then(res => {
          this.maintTypeList = res.data;
          this.maintTypeList.forEach(type => {
            if (type.isDefault === 1) {
              if (type.maintTypeName === "半月保") {
                this.halfMonth = type;
              }
              if (type.maintTypeName === "季度保") {
                this.month = type;
              }
              if (type.maintTypeName === "半年保") {
                this.halfYear = type;
              }
              if (type.maintTypeName === "年度保") {
                this.year = type;
              }
            }
          });
        }).catch(error => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      onDialogClose() {
        this.saveDisabled = false;
        this.$refs.formValidate.resetFields();
        this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
      },
      handleSubmit() {
        this.$refs.formValidate.validate((valid) => {
          if (this.maintPlanList.length === 0) {
            this.$message.error(this.$l("maintWorkOrder.tip.emptyPlan","新增保养计划不能为空！"));
            return;
          }
          if (valid) {
            this.submitLoading = true;
            this.maintPlanList.forEach(maintPlan => {
              maintPlan.maintDate = new Date(maintPlan.maintDate).toJSON().split("T")[0];
            });
            let params = {
              maintPlanList: this.maintPlanAddList,
              elevatorList: this.elevatorList,
            };
            this.$http.post(moduleName, params).then(() => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success");
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          } else {
            this.$message.error(this.$t("common.tip.submitError") + "!");
          }
        });
      },
      handleMaintType(scope, item) {
        scope.row.maintType = item.id;
        scope.row.maintTypeName = item.maintTypeName;
        let endDate = this.maintPlanTemp.planEndDate;
        let planStartDate = scope.row.maintDate;
        let startDate = JSON.parse(JSON.stringify(scope.row.maintDate));
        if (item.maintTypeName === "季度保") {
          startDate = new Date(Date.parse(startDate) + 90*24*3600*1000);
          endDate = new Date(Date.parse(endDate) + 90*24*3600*1000);
        } else if (item.maintTypeName === "半年保") {
          startDate = new Date(Date.parse(startDate) + 180*24*3600*1000);
          endDate = new Date(Date.parse(endDate) + 180*24*3600*1000);
        } else if (item.maintTypeName === "年度保") {
          startDate = new Date(Date.parse(startDate) + 360*24*3600*1000);
          endDate = new Date(Date.parse(endDate) + 360*24*3600*1000);
        }
        let index = scope.$index + 1;
        let maintPlan = {};
        while (Date.parse(endDate) - this.maintPlanTemp.maintCycle * 24*3600*1000 >= Date.parse(startDate)) {
          startDate = new Date(Date.parse(startDate) + this.maintPlanTemp.maintCycle * 24*3600*1000);
          let days = (startDate.getTime() - new Date(planStartDate).getTime()) / (24*3600*1000);
          if (days % 360 >= 0 && days % 360 < this.maintPlanTemp.maintCycle) {
            maintPlan.maintType = this.year.id;
            maintPlan.maintTypeName = this.year.maintTypeName;
          } else if (days % 180 >= 0 && days % 180 < this.maintPlanTemp.maintCycle) {
            maintPlan.maintType = this.halfYear.id;
            maintPlan.maintTypeName = this.halfYear.maintTypeName;
          } else if (days % 90 >= 0 && days % 90 < this.maintPlanTemp.maintCycle) {
            maintPlan.maintType = this.month.id;
            maintPlan.maintTypeName = this.month.maintTypeName;
          } else {
            maintPlan.maintType = this.halfMonth.id;
            maintPlan.maintTypeName = this.halfMonth.maintTypeName;
          }
          this.maintPlanList.at(index).maintType = maintPlan.maintType;
          this.maintPlanList.at(index).maintType = maintPlan.maintTypeName;
          index = index + 1;
        }
      },
      handleGeneratePlan() {
        this.$refs.formValidate.validate((valid) => {
          if (Date.parse(this.maintPlanTemp.planStartDate) >= Date.parse(this.maintPlanTemp.planEndDate)) {
            this.$message.error(this.$l("maintWorkOrder.tip.timeError","开始时间需大于结束时间"));
            return;
          }
          this.saveDisabled = false;
          if (valid) {
            this.maintPlanList.splice(this.maintPlanList.length - this.maintPlanAddList.length);
            this.maintPlanAddList = [];
            let endDate = this.maintPlanTemp.planEndDate;
            let startDate = this.maintPlanTemp.planStartDate;
            if (this.maintPlanList.length > 0 && this.maintPlanList[this.maintPlanList.length - 1].editDisabled) {
              startDate = this.maintPlanList[this.maintPlanList.length - 1].maintDate;
            } else if (Date.parse(endDate) - this.maintPlanTemp.maintCycle * 24*3600*1000 >= Date.parse(startDate)) {
              let maintPlan = {};
              maintPlan.maintDate = startDate;
              maintPlan.maintType =  this.halfMonth.id;
              maintPlan.maintTypeName = this.halfMonth.maintTypeName;
              this.maintPlanAddList.push(maintPlan);
            }
            while (Date.parse(endDate) - this.maintPlanTemp.maintCycle * 24*3600*1000 >= Date.parse(startDate)) {
              let maintPlan = {};
              let planStartDate = JSON.parse(JSON.stringify(this.maintPlanTemp.planStartDate));
              startDate = new Date(Date.parse(startDate) + this.maintPlanTemp.maintCycle * 24*3600*1000);
              maintPlan.maintDate = startDate;
              let days = (startDate.getTime() - new Date(planStartDate).getTime()) / (24*3600*1000);
              if (days % 360 >= 0 && days % 360 < this.maintPlanTemp.maintCycle) {
                maintPlan.maintType = this.year.id;
                maintPlan.maintTypeName = this.year.maintTypeName;
              } else if (days % 180 >= 0 && days % 180 < this.maintPlanTemp.maintCycle) {
                maintPlan.maintType = this.halfYear.id;
                maintPlan.maintTypeName = this.halfYear.maintTypeName;
              } else if (days % 90 >= 0 && days % 90 < this.maintPlanTemp.maintCycle) {
                maintPlan.maintType = this.month.id;
                maintPlan.maintTypeName = this.month.maintTypeName;
              } else {
                maintPlan.maintType = this.halfMonth.id;
                maintPlan.maintTypeName = this.halfMonth.maintTypeName;
              }
              this.maintPlanAddList.push(maintPlan);
            }
            this.maintPlanList.push(...this.maintPlanAddList);
          }
        });
      },
      handleDateChange(scope) {
        if (scope.$index > 0 && Date.parse(scope.row.maintDate) - Date.parse(this.maintPlanList[scope.$index - 1].maintDate) <= 0) {
          this.$message.error(this.$l("maintWorkOrder.tip.timeError","开始时间需大于结束时间"));
          this.saveDisabled = true;
          return;
        } else if (scope.$index > 0 && Date.parse(scope.row.maintDate) - Date.parse(this.maintPlanList[scope.$index - 1].maintDate) > this.maintPlanTemp.maintCycle * 24*3600*1000) {
          this.$message.error(this.$l("maintWorkOrder.tip.timeError1","当前日期间隔超出保养周期"));
          this.saveDisabled = true;
          return;
        } else {
          this.saveDisabled = false;
        }
        let maintPlanAddList = [];
        let startDate = scope.row.maintDate;
        let endDate = this.maintPlanTemp.planEndDate;
        let planStartDate = JSON.parse(JSON.stringify(this.maintPlanAddList.at(0).maintDate));
        while (Date.parse(endDate) - this.maintPlanTemp.maintCycle * 24*3600*1000 >= Date.parse(startDate)) {
          let maintPlan = {};
          startDate = new Date(Date.parse(startDate) + this.maintPlanTemp.maintCycle * 24*3600*1000);
          maintPlan.maintDate = startDate;
          let days = (startDate.getTime() - new Date(planStartDate).getTime()) / (24*3600*1000);
          if (days % 360 >= 0 && days % 360 < this.maintPlanTemp.maintCycle) {
            maintPlan.maintType = this.year.id;
            maintPlan.maintTypeName = this.year.maintTypeName;
          } else if (days % 180 >= 0 && days % 180 < this.maintPlanTemp.maintCycle) {
            maintPlan.maintType = this.halfYear.id;
            maintPlan.maintTypeName = this.halfYear.maintTypeName;
          } else if (days % 90 >= 0 && days % 90 < this.maintPlanTemp.maintCycle) {
            maintPlan.maintType = this.month.id;
            maintPlan.maintTypeName = this.month.maintTypeName;
          } else {
            maintPlan.maintType = this.halfMonth.id;
            maintPlan.maintTypeName = this.halfMonth.maintTypeName;
          }
          maintPlanAddList.push(maintPlan);
        }
        this.maintPlanAddList.splice(this.maintPlanAddList.findIndex(item => item.maintDate === scope.row.maintDate) + 1);
        this.maintPlanAddList.push(...maintPlanAddList);
        this.maintPlanList.splice(scope.$index + 1);
        this.maintPlanList.push(...maintPlanAddList);
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
