<template>
  <el-dialog
    :title="$l('maintWorkOrder.invalid','作废工单')"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="800px"
    @close="onDialogClose">
    <el-form
      ref="formValidate"
      label-width="100px"
      :model="maintWorkOrder"
      :rules="ruleValidate">
      <el-form-item :label="$l('maintWorkOrder.invalidReason','作废原因')" prop="invalidReason">
        <el-input
          v-model="maintWorkOrder.invalidReason"
          type="textarea"
          :rows="5"
          maxlength="100"
          :placeholder="$t('common.pleaseEnter')">
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button
        type="primary"
        :loading="submitLoading"
        :disabled="saveDisabled"
        @click="handleSubmit">{{$t("common.confirm")}}</el-button>
    </span>
  </el-dialog>
</template>
<script>

  const moduleName = "maintenanceOrder";
  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        submitLoading: false,
        saveDisabled: false,
        maintWorkOrder: {
          id: 0,
          invalidReason: "",
        },
        ruleValidate: {
          invalidReason: [
            {required: true, message: this.$l("maintWorkOrder.tip.invalidReason","作废原因不能为空"), trigger: "blur"},
          ],
        },
      };
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        this.maintWorkOrder.id = id;
      },
      onDialogClose() {
        this.saveDisabled = false;
        this.$refs.formValidate.resetFields();
        this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
      },
      handleSubmit() {
        this.$refs.formValidate.validate((valid) => {
          let params = {
            id: this.maintWorkOrder.id,
            reason: this.maintWorkOrder.invalidReason,
          };
          if (valid) {
            this.submitLoading = true;
            this.$http.post(moduleName + "/invalid", null, params).then(() => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$message.success(this.$l("maintWorkOrder.tip.invalidSuccess","作废成功"));
              this.$emit("invalid-success");
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$l("maintWorkOrder.tip.invalidError","作废失败") + "," + error.response.data.message);
            });
          } else {
            this.$message.error(this.$t("common.tip.submitError") + "!");
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
