<template>
  <div>
    <el-tabs v-model="activeName" ref="tabs" @tab-click="handleTabClick">
      <el-tab-pane :label="$l('maintWorkOrder.all', '全部')">
        <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" :multi-selection="true"
          :reserve-selection="true" url="maintenanceOrder" @row-click="handleRowClick"
          @selection-change="handleSelectionChange" @clear="clearDateRange">
          <template slot="toolbar">
            <el-button v-if="maintAuth" class="addBtn" icon="el-icon-plus" @click="handleAdd">
              {{ $t("common.add") }}
            </el-button>
            <el-button v-if="maintAuth" class="dangerBtn" :disabled="deleteDisable" @click="handleDelete">
              {{ $t("common.delete") }}
            </el-button>
            <el-button v-if="maintAuth" class="greenBtn" :disabled="sendDisable" @click="sendWorkOrder()">
              {{ $l("maintWorkOrder.send", "发送") }}
            </el-button>
            <el-button :disabled="completionSituationDisable" @click="checkCompletionSituation">
              {{ $l("maintWorkOrder.completionSituation", "完成情况") }}
            </el-button>
            <el-button :disabled="maintPlanDisable" @click="checkMaintPlan">
              {{ $l("maintWorkOrder.maintPlan", "保养计划") }}
            </el-button>
            <el-button @click="handleClearSelection">
              {{ $l("maintWorkOrder.clearSelection", "清空多选") }}
            </el-button>
          </template>
          <template slot="adSearch">
            <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
              <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
            </vm-search>
            <vm-search :label="$l('maintWorkOrder.maintStatus', '状态')">
              <el-select v-model="search.maintStatus" style="width:130px" :placeholder="$t('common.pleaseSelect')"
                clearable>
                <el-option v-for="item in stateOptions" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </vm-search>
            <vm-search :label="$l('maintWorkOrder.completionStatus', '完成状态')">
              <el-select v-model="search.completionStatus" style="width:130px" :placeholder="$t('common.pleaseSelect')"
                clearable>
                <el-option v-for="item in completionStateOptions" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </vm-search>
            <vm-search v-if="$i18n.isCn" :label="$l('elevator.accessPlatform', '对接平台')">
              <el-select v-model="search.integrationKey" :placeholder="$t('elevator.accessPlatform')"
                :style="{ width: $l('160px', '150px') }" clearable>
                <el-option v-for="item in integrationList" :key="item.integrationKey" :label="item.name"
                  :value="item.integrationKey"></el-option>
              </el-select>
            </vm-search>
            <vm-search :label="$l('maintWorkOrder.planDate', '计划保养日期')">
              <el-date-picker v-model="dateRange" value-format="yyyy-MM-dd" type="daterange" :unlink-panels="true"
                :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
                :end-placeholder="$t('common.endDate')" @change="handleDateChange">
              </el-date-picker>
            </vm-search>
          </template>
          <el-table-column prop="orderNum" :label="$l('maintWorkOrder.orderNum', '单号')" width="120" align="center">
            <template v-slot="scope">
              <router-link :to="'/newMaintWorkOrder/' + scope.row.id" target="_blank">{{ scope.row.orderNum }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column prop="elevatorNo" :label="$l('elevator.no', '电梯编号')" width="120" align="center">
            <template v-slot="scope">
              <router-link :to="'/elevator/' + scope.row.elevatorId" target="_blank">{{ scope.row.elevatorNo }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column prop="elevatorName" show-overflow-tooltip
            :label="$l('maintWorkOrder.elevatorName', '电梯名称')"></el-table-column>
          <el-table-column prop="useUnitName" show-overflow-tooltip
            :label="$l('elevator.useUnit', '使用单位')"></el-table-column>
          <el-table-column prop="maintComName" show-overflow-tooltip
            :label="$l('maintWorkOrder.company', '维保单位')"></el-table-column>
          <el-table-column prop="propertyComName" :label="$l('maintWorkOrder.propertyCom', '物业单位')" show-overflow-tooltip></el-table-column>
          <el-table-column prop="maintDate" :label="$l('maintWorkOrder.maintDate', '保养日期')" width="90px"
            align="center"></el-table-column>
          <el-table-column prop="maintTypeName" :label="$l('maintWorkOrder.maintType', '保养类型')"
            :width="$l('100px', '80px')" align="center"></el-table-column>
          <el-table-column prop="completionTime" :label="$l('maintWorkOrder.completionTime', '完成时间')" width="140"
            align="center"></el-table-column>
          <el-table-column prop="createTime" :label="$l('callRecord.createTime', '创建时间')" width="140"
                           align="center"></el-table-column>
          <el-table-column prop="maintDuration" :label="$l('maintWorkOrder.maintDuration', '保养时长')"
            :width="$l('120px', '100')" align="center">
            <template v-slot="scope">
              <span v-if="scope.row.maintDuration !== null">
                {{ scope.row.maintDuration + $l(" minutes", " 分钟") }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="maintainerName1" :label="$l('elevator.maintWorker', '维保人员') + '1'"
            :width="$l('130', '100')" align="center"></el-table-column>
          <el-table-column prop="maintainerName2" :label="$l('elevator.maintWorker', '维保人员') + '2'"
            :width="$l('130', '100')" align="center"></el-table-column>
          <el-table-column prop="completionStatus" :label="$l('maintWorkOrder.completionStatus', '完成状态')"
            :width="$l('110', '100')" align="center">
            <template v-slot="scope">
              <el-tag v-if="scope.row.completionStatus === 1" type="success">
                {{ $l("maintWorkOrder.normal", "正常") }}
              </el-tag>
              <el-tag v-else-if="scope.row.completionStatus === 2">
                {{ $l("maintWorkOrder.advance", "提前") }}
              </el-tag>
              <el-tag v-else-if="scope.row.completionStatus === 3" type="danger">
                {{ $l("maintWorkOrder.overdue", "超期") }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="maintStatus" :label="$l('maintWorkOrder.maintStatus', '状态')" :width="$l('120', '80')"
            align="center">
            <template v-slot="scope">
              <el-tag v-if="scope.row.maintStatus === 0" type="danger"> {{ $l("maintWorkOrder.invalid", "作废") }}</el-tag>
              <el-tag v-else-if="scope.row.maintStatus === 1">
                {{ $l("maintWorkOrder.inPlan", "计划中") }}
              </el-tag>
              <el-tag v-else-if="scope.row.maintStatus === 2" type="warning">
                {{ $l("maintWorkOrder.notAccepted", "未接受") }}
              </el-tag>
              <el-tag v-else-if="scope.row.maintStatus === 3">
                {{ $l("maintWorkOrder.accepted", "已接受") }}
              </el-tag>
              <el-tag v-else-if="scope.row.maintStatus === 4">
                {{ $l("maintWorkOrder.signedIn", "已签到") }}
              </el-tag>
              <el-tag v-else-if="scope.row.maintStatus === 5" type="success">
                {{ $l("maintWorkOrder.completed", "已完成") }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="creator" :label="$l('maintWorkOrder.creator', '建单人')" width="80"
            align="center"></el-table-column>
          <el-table-column v-if="maintAuth" fixed="right" align="center" width="100" :label="$t('common.operation')">
            <template v-slot="scope">
              <el-button type="text"
                :disabled="(scope.row.maintStatus !== 1 && scope.row.maintStatus !== 2) || (scope.row.completionStatus === 3 && operationDisable) || scope.row.maintTypeName === '旧维保'"
                @click="handleEdit(scope.row)">
                {{ $t("common.edit") }}
              </el-button>
              <el-button type="text" class="operateDelBtn"
                :disabled="(scope.row.maintStatus !== 3 && scope.row.maintStatus !== 4) || (scope.row.completionStatus === 3 && operationDisable) || scope.row.maintTypeName === '旧维保'"
                @click="handleInvalid(scope.row)">
                {{ $l("maintWorkOrder.invalid", "作废") }}
              </el-button>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="orderNum" :label="$l('maintWorkOrder.orderNum', '单号')" width="100px">
            <template v-slot="scope">
              <router-link :to="'/newMaintWorkOrder/' + scope.row.id" target="_blank">{{ scope.row.orderNum }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column prop="elevatorNo" :label="$l('elevator.no', '电梯编号')" width="125px">
            <template v-slot="scope">
              <router-link :to="'/elevator/' + scope.row.elevatorId" target="_blank">{{ scope.row.elevatorNo }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column prop="elevatorName" show-overflow-tooltip
            :label="$l('maintWorkOrder.elevatorName', '电梯名称')"></el-table-column>
          <el-table-column prop="useUnitName" show-overflow-tooltip
            :label="$l('elevator.useUnit', '使用单位')"></el-table-column>
          <el-table-column prop="maintComName" show-overflow-tooltip
            :label="$l('maintWorkOrder.company', '维保单位')"></el-table-column>
          <el-table-column prop="propertyComName" :label="$l('maintWorkOrder.propertyCom', '物业单位')"></el-table-column>
          <el-table-column prop="maintDate" :label="$l('maintWorkOrder.maintDate', '保养日期')" width="90px"
            align="center"></el-table-column>
          <el-table-column prop="maintTypeName" :label="$l('maintWorkOrder.maintType', '保养类型')"
            :width="$l('100px', '80px')" align="center"></el-table-column>
          <el-table-column prop="completionTime" :label="$l('maintWorkOrder.completionTime', '完成时间')" width="140px"
            align="center"></el-table-column>
          <el-table-column prop="maintDuration" :label="$l('maintWorkOrder.maintDuration', '保养时长')"
            :width="$l('120px', '85px')" align="center">
            <template v-slot="scope">
              <span v-if="scope.row.maintDuration !== null">
                {{ scope.row.maintDuration + $l(" minutes", " 分钟") }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="maintainerName1" :label="$l('elevator.maintWorker', '维保人员') + '1'"
            :width="$l('130px', '90px')"></el-table-column>
          <el-table-column prop="maintainerName2" :label="$l('elevator.maintWorker', '维保人员') + '2'"
            :width="$l('130px', '90px')"></el-table-column>
          <el-table-column prop="completionStatus" :label="$l('maintWorkOrder.completionStatus', '完成状态')"
            :width="$l('110px', '80px')" align="center">
            <template v-slot="scope">
              <el-tag v-if="scope.row.completionStatus === 1" class="normal">
                {{ $l("maintWorkOrder.normal", "正常") }}
              </el-tag>
              <el-tag v-else-if="scope.row.completionStatus === 2" class="primary">
                {{ $l("maintWorkOrder.advance", "提前") }}
              </el-tag>
              <el-tag v-else-if="scope.row.completionStatus === 3" class="abnormal">
                {{ $l("maintWorkOrder.overdue", "超期") }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="maintStatus" :label="$l('maintWorkOrder.maintStatus', '状态')" :width="$l('120px', '70px')"
            align="center">
            <template v-slot="scope">
              <el-tag v-if="scope.row.maintStatus === 0" class="abnormal"> {{ $l("maintWorkOrder.invalid", "作废")
              }}</el-tag>
              <el-tag v-else-if="scope.row.maintStatus === 1" class="warning">
                {{ $l("maintWorkOrder.inPlan", "计划中") }}
              </el-tag>
              <el-tag v-else-if="scope.row.maintStatus === 2" class="primary">
                {{ $l("maintWorkOrder.notAccepted", "未接受") }}
              </el-tag>
              <el-tag v-else-if="scope.row.maintStatus === 3" class="primary">
                {{ $l("maintWorkOrder.accepted", "已接受") }}
              </el-tag>
              <el-tag v-else-if="scope.row.maintStatus === 4" class="info">
                {{ $l("maintWorkOrder.signedIn", "已签到") }}
              </el-tag>
              <el-tag v-else-if="scope.row.maintStatus === 5" class="normal">
                {{ $l("maintWorkOrder.completed", "已完成") }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="creator" :label="$l('maintWorkOrder.creator', '建单人')" width="95px"
            align="center"></el-table-column>
          <el-table-column v-if="maintAuth" fixed="right" align="center" width="90px" :label="$t('common.operation')">
            <template v-slot="scope">
              <el-button type="text"
                :disabled="(scope.row.maintStatus !== 1 && scope.row.maintStatus !== 2) || (scope.row.completionStatus === 3 && operationDisable) || scope.row.maintTypeName === '旧维保'"
                @click="handleEdit(scope.row)">
                {{ $t("common.edit") }}
              </el-button>
              <el-button type="text" class="operateDelBtn"
                :disabled="(scope.row.maintStatus !== 3 && scope.row.maintStatus !== 4) || (scope.row.completionStatus === 3 && operationDisable) || scope.row.maintTypeName === '旧维保'"
                @click="handleInvalid(scope.row)">
                {{ $l("maintWorkOrder.invalid", "作废") }}
              </el-button>
            </template>
          </el-table-column> -->
        </vm-table>
      </el-tab-pane>
      <el-tab-pane :label="$l('maintWorkOrder.maintPlan', '保养计划')">
        <vm-table ref="maintPlan" v-loading="planLoading" :filter.sync="searchPlan" :multi-selection="true"
          :reserve-selection="true" url="maintPlan" @row-click="handleRowClickPlan"
          @selection-change="handleSelectionChangePlan">
          <template slot="toolbar">
            <div style="display: flex;flex-direction: row;justify-content: space-between">
              <div>
                <el-button v-if="maintAuth" class="addBtn" icon="el-icon-plus" :disabled="planAddDisable"
                  @click="handleAddPlan">
                  {{ $l("common.add", "新增") }}
                </el-button>
                <el-button v-if="maintAuth" :disabled="planEditDisable" @click="handleEditPlan">
                  {{ $t("common.edit") }}
                </el-button>
                <el-button v-if="maintAuth" class="dangerBtn" :disabled="planInvalidDisable" @click="handlePlanInvalid">
                  {{ $l("maintWorkOrder.invalid", "作废") }}
                </el-button>
              </div>
              <div style="width: 350px">
                <div slot="header" class="clearfix"
                  style="font-size: 13px;display: flex;flex-direction: row;justify-content: space-between">
                  <div>{{ $l("maintWorkOrder.plan.selected", "已选电梯（可添加计划）") + availablePlan.length +
                    $l("maintWorkOrder.plan.row", " 条：") }}</div>
                  <div style="background: #F2F2F2;color: #0747FD;cursor: pointer" @click="handlePlanClear">
                    {{ $l("maintWorkOrder.clearSelection", "清空多选") }}
                  </div>
                </div>
                <el-tag v-for="tag in availablePlan" :key="tag.no" size="medium" style="margin-right: 10px" closable
                  @close="handleTagClose(tag)">
                  {{ tag.no }}
                </el-tag>
              </div>
            </div>
          </template>
          <template slot="adSearch">
            <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
              <el-input v-model.trim="searchPlan.filter" :placeholder="$t('common.search')" clearable></el-input>
            </vm-search>
            <vm-search :label="$l('maintWorkOrder.haveMaintPlan', '是否已有计划')">
              <el-select v-model="searchPlan.maintPlan" :placeholder="$t('common.pleaseSelect')" style="width: 100%"
                clearable>
                <el-option :label="$l('maintWorkOrder.plan.have', '有')" :value="true"></el-option>
                <el-option :label="$l('maintWorkOrder.plan.no', '无')" :value="false"></el-option>
              </el-select>
            </vm-search>
          </template>
          <el-table-column prop="no" :label="$l('elevator.no', '电梯编号')" width="120px" align="center">
            <template v-slot="scope">
              <router-link :to="'/elevator/' + scope.row.id" target="_blank">{{ scope.row.no }}</router-link>
            </template>
          </el-table-column>
          <el-table-column prop="name" :label="$l('maintWorkOrder.elevatorName', '电梯名称')" show-overflow-tooltip></el-table-column>
          <el-table-column prop="useUnitName" :label="$l('elevator.useUnit', '使用单位')" show-overflow-tooltip></el-table-column>
          <el-table-column prop="maintComName" :label="$l('maintWorkOrder.company', '维保企业')" show-overflow-tooltip></el-table-column>
          <el-table-column prop="productTypeName" :label="$l('maintWorkOrder.productTypeName', '梯种')"
            :width="$l('220px', '130px')">
            <template v-if="!$i18n.isCn" v-slot="scope">
              <span v-if="scope.row.productTypeName === '曳引驱动乘客电梯'">
                {{ "Traction Drive Passenger Elevator" }}
              </span>
              <span v-if="scope.row.productTypeName === '曳引驱动载货电梯'">
                {{ "Traction Drive Freight Elevator" }}
              </span>
              <span v-if="scope.row.productTypeName === '强制驱动载货电梯'">
                {{ "Forced Drive Freight Elevator" }}
              </span>
              <span v-if="scope.row.productTypeName === '液压乘客电梯'">
                {{ "Hydraulic Passenger Elevator" }}
              </span>
              <span v-if="scope.row.productTypeName === '液压载货电梯'">
                {{ "Hydraulic Freight Elevator" }}
              </span>
              <span v-if="scope.row.productTypeName === '自动扶梯'">
                {{ "Escalator" }}
              </span>
              <span v-if="scope.row.productTypeName === '自动人行道'">
                {{ "Moving Walk" }}
              </span>
              <span v-if="scope.row.productTypeName === '防爆电梯'">
                {{ "Explosion-proof Elevator" }}
              </span>
              <span v-if="scope.row.productTypeName === '消防员电梯'">
                {{ "Firefighter Elevator" }}
              </span>
              <span v-if="scope.row.productTypeName === '杂物电梯'">
                {{ "Debris Elevator" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="maintPlan" :label="$l('maintWorkOrder.haveMaintPlan', '有无保养计划')" width="110px"
            align="center">
            <template v-slot="scope">
              <span v-if="scope.row.maintPlan">{{ $l("maintWorkOrder.plan.have", "有") }}</span>
              <span v-else>{{ $l("maintWorkOrder.plan.no", "无") }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="planStartTime" :label="$l('maintWorkOrder.plan.planStartTime', '计划开始日期')"
            :width="$l('120px', '110')" align="center"></el-table-column>
          <el-table-column prop="planEndTime" :label="$l('maintWorkOrder.plan.planEndTime', '计划结束日期')"
            :width="$l('120px', '110')" align="center"></el-table-column>
          <el-table-column prop="maintainerName1" :label="$l('elevator.maintWorker', '维保人员') + '1'"
            :width="$l('120px', '100')"></el-table-column>
          <el-table-column prop="maintainerName2" :label="$l('elevator.maintWorker', '维保人员') + '2'"
            :width="$l('120px', '100')"></el-table-column>
        </vm-table>
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label">
          {{ $l("maintWorkOrder.recentNotAccept", "最后24小时未接受") }}
          <el-badge :value="notAcceptTotal" :max="99" class="item"></el-badge>
        </span>
        <vm-table ref="notAcceptTable" v-loading="notAcceptLoading" :filter.sync="searchNotAccept" :multi-selection="true"
          :reserve-selection="true" url="maintenanceOrder" @row-click="handleRowClickNotAccept"
          @selection-change="handleSelectionChange" @clear="clearDateRange">
          <template slot="toolbar">
            <el-button v-if="maintAuth" class="dangerBtn" :disabled="deleteDisable" @click="handleDelete">
              {{ $t("common.delete") }}
            </el-button>
            <el-button :disabled="completionSituationDisable" @click="checkCompletionSituation">
              {{ $l("maintWorkOrder.completionSituation", "完成情况") }}
            </el-button>
            <el-button :disabled="maintPlanDisable" @click="checkMaintPlan">
              {{ $l("maintWorkOrder.maintPlan", "保养计划") }}
            </el-button>
            <el-button @click="handleClearUnAcceptSelection">
              {{ $l("maintWorkOrder.clearSelection", "清空多选") }}
            </el-button>
          </template>
          <template slot="adSearch">
            <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
              <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
            </vm-search>
            <vm-search :label="$l('maintWorkOrder.completionStatus', '完成状态')">
              <el-select v-model="search.completionStatus" :placeholder="$t('common.pleaseSelect')" clearable>
                <el-option v-for="item in completionStateOptions" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </vm-search>
            <vm-search :label="$l('maintWorkOrder.planDate', '计划保养日期')">
              <el-date-picker v-model="dateRange" value-format="yyyy-MM-dd" type="daterange" :unlink-panels="true"
                :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
                :end-placeholder="$t('common.endDate')" @change="handleDateChange">
              </el-date-picker>
            </vm-search>
          </template>
          <el-table-column prop="orderNum" :label="$l('maintWorkOrder.orderNum', '单号')" width="120" align="center">
            <template v-slot="scope">
              <router-link :to="'/newMaintWorkOrder/' + scope.row.id" target="_blank">{{ scope.row.orderNum }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column prop="elevatorNo" :label="$l('elevator.no', '电梯编号')" width="120" align="center">
            <template v-slot="scope">
              <router-link :to="'/elevator/' + scope.row.elevatorId" target="_blank">{{ scope.row.elevatorNo }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column prop="elevatorName" show-overflow-tooltip
            :label="$l('maintWorkOrder.elevatorName', '电梯名称')"></el-table-column>
          <el-table-column prop="useUnitName" show-overflow-tooltip
            :label="$l('elevator.useUnit', '使用单位')"></el-table-column>
          <el-table-column prop="maintComName" show-overflow-tooltip
            :label="$l('maintWorkOrder.company', '维保单位')"></el-table-column>
          <el-table-column prop="propertyComName" :label="$l('maintWorkOrder.propertyCom', '物业单位')"></el-table-column>
          <el-table-column prop="maintDate" :label="$l('maintWorkOrder.maintDate', '保养日期')" width="90px"
            align="center"></el-table-column>
          <el-table-column prop="maintTypeName" :label="$l('maintWorkOrder.maintType', '保养类型')"
            :width="$l('100px', '80px')" align="center"></el-table-column>
          <el-table-column prop="completionTime" :label="$l('maintWorkOrder.completionTime', '完成时间')" width="140"
            align="center"></el-table-column>
          <el-table-column prop="maintDuration" :label="$l('maintWorkOrder.maintDuration', '保养时长')"
            :width="$l('120px', '100')" align="center">
            <template v-slot="scope">
              <span v-if="scope.row.maintDuration !== null">
                {{ scope.row.maintDuration + $l(" minutes", " 分钟") }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="maintainerName1" :label="$l('elevator.maintWorker', '维保人员') + '1'"
            :width="$l('130', '100')" align="center"></el-table-column>
          <el-table-column prop="maintainerName2" :label="$l('elevator.maintWorker', '维保人员') + '2'"
            :width="$l('130', '100')" align="center"></el-table-column>
          <el-table-column prop="completionStatus" :label="$l('maintWorkOrder.completionStatus', '完成状态')"
            :width="$l('110', '100')" align="center">
            <template v-slot="scope">
              <el-tag v-if="scope.row.completionStatus === 1" type="success">
                {{ $l("maintWorkOrder.normal", "正常") }}
              </el-tag>
              <el-tag v-else-if="scope.row.completionStatus === 2">
                {{ $l("maintWorkOrder.advance", "提前") }}
              </el-tag>
              <el-tag v-else-if="scope.row.completionStatus === 3" type="danger">
                {{ $l("maintWorkOrder.overdue", "超期") }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="maintStatus" :label="$l('maintWorkOrder.maintStatus', '状态')" :width="$l('120', '80')"
            align="center">
            <template v-slot="scope">
              <el-tag v-if="scope.row.maintStatus === 0" type="danger"> {{ $l("maintWorkOrder.invalid", "作废") }}</el-tag>
              <el-tag v-else-if="scope.row.maintStatus === 1">
                {{ $l("maintWorkOrder.inPlan", "计划中") }}
              </el-tag>
              <el-tag v-else-if="scope.row.maintStatus === 2" type="warning">
                {{ $l("maintWorkOrder.notAccepted", "未接受") }}
              </el-tag>
              <el-tag v-else-if="scope.row.maintStatus === 3">
                {{ $l("maintWorkOrder.accepted", "已接受") }}
              </el-tag>
              <el-tag v-else-if="scope.row.maintStatus === 4">
                {{ $l("maintWorkOrder.signedIn", "已签到") }}
              </el-tag>
              <el-tag v-else-if="scope.row.maintStatus === 5" type="success">
                {{ $l("maintWorkOrder.completed", "已完成") }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="creator" :label="$l('maintWorkOrder.creator', '建单人')" width="80"
            align="center"></el-table-column>
          <el-table-column v-if="maintAuth" fixed="right" align="center" width="100" :label="$t('common.operation')">
            <template v-slot="scope">
              <el-button type="text"
                :disabled="(scope.row.maintStatus !== 1 && scope.row.maintStatus !== 2) || (scope.row.completionStatus === 3 && operationDisable) || scope.row.maintTypeName === '旧维保'"
                @click="handleEdit(scope.row)">
                {{ $t("common.edit") }}
              </el-button>
              <el-button type="text" class="operateDelBtn"
                :disabled="(scope.row.maintStatus !== 3 && scope.row.maintStatus !== 4) || (scope.row.completionStatus === 3 && operationDisable) || scope.row.maintTypeName === '旧维保'"
                @click="handleInvalid(scope.row)">
                {{ $l("maintWorkOrder.invalid", "作废") }}
              </el-button>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="orderNum" :label="$l('maintWorkOrder.orderNum', '单号')" width="100px">
            <template v-slot="scope">
              <router-link :to="'/newMaintWorkOrder/' + scope.row.id" target="_blank">{{ scope.row.orderNum }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column prop="elevatorNo" :label="$l('elevator.no', '电梯编号')" width="125px">
            <template v-slot="scope">
              <router-link :to="'/elevator/' + scope.row.elevatorId" target="_blank">{{ scope.row.elevatorNo }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column prop="elevatorName" :label="$l('maintWorkOrder.elevatorName', '电梯名称')"></el-table-column>
          <el-table-column prop="useUnitName" :label="$l('elevator.useUnit', '使用单位')"></el-table-column>
          <el-table-column prop="maintComName" :label="$l('maintWorkOrder.company', '维保单位')"></el-table-column>
          <el-table-column prop="propertyComName" :label="$l('maintWorkOrder.propertyCom', '物业单位')"></el-table-column>
          <el-table-column prop="maintDate" :label="$l('maintWorkOrder.maintDate', '保养日期')" width="90px"
            align="center"></el-table-column>
          <el-table-column prop="maintTypeName" :label="$l('maintWorkOrder.maintType', '保养类型')"
            :width="$l('90px', '70px')" align="center"></el-table-column>
          <el-table-column prop="completionTime" :label="$l('maintWorkOrder.completionTime', '完成时间')" width="140px"
            align="center"></el-table-column>
          <el-table-column prop="maintDuration" :label="$l('maintWorkOrder.maintDuration', '保养时长')"
            :width="$l('120px', '85px')" align="center">
            <template v-slot="scope">
              <span v-if="scope.row.maintDuration !== null">
                {{ scope.row.maintDuration + $l(" minutes", " 分钟") }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="maintainerName1" :label="$l('elevator.maintWorker', '维保人员') + '1'"
            :width="$l('130px', '90px')"></el-table-column>
          <el-table-column prop="maintainerName2" :label="$l('elevator.maintWorker', '维保人员') + '2'"
            :width="$l('130px', '90px')"></el-table-column>
          <el-table-column prop="completionStatus" :label="$l('maintWorkOrder.completionStatus', '完成状态')"
            :width="$l('110px', '80px')" align="center">
            <template v-slot="scope">
              <el-tag v-if="scope.row.completionStatus === 1" class="normal">
                {{ $l("maintWorkOrder.normal", "正常") }}
              </el-tag>
              <el-tag v-else-if="scope.row.completionStatus === 2" class="primary">
                {{ $l("maintWorkOrder.advance", "提前") }}
              </el-tag>
              <el-tag v-else-if="scope.row.completionStatus === 3" class="abnormal">
                {{ $l("maintWorkOrder.overdue", "超期") }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="maintStatus" :label="$l('maintWorkOrder.maintStatus', '状态')" :width="$l('120px', '70px')"
            align="center">
            <template v-slot="scope">
              <el-tag v-if="scope.row.maintStatus === 0" type="danger"> {{ $l("maintWorkOrder.invalid", "作废") }}</el-tag>
              <el-tag v-else-if="scope.row.maintStatus === 1" class="warning">
                {{ $l("maintWorkOrder.inPlan", "计划中") }}
              </el-tag>
              <el-tag v-else-if="scope.row.maintStatus === 2" class="info">
                {{ $l("maintWorkOrder.notAccepted", "未接受") }}
              </el-tag>
              <el-tag v-else-if="scope.row.maintStatus === 3" class="primary">
                {{ $l("maintWorkOrder.accepted", "已接受") }}
              </el-tag>
              <el-tag v-else-if="scope.row.maintStatus === 4" class="info">
                {{ $l("maintWorkOrder.signedIn", "已签到") }}
              </el-tag>
              <el-tag v-else-if="scope.row.maintStatus === 5" class="normal">
                {{ $l("maintWorkOrder.completed", "已完成") }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="creator" :label="$l('maintWorkOrder.creator', '建单人')" width="70px"
            align="center"></el-table-column>
          <el-table-column v-if="maintAuth" fixed="right" align="center" :width="$l('90px', '70px')"
            :label="$t('common.operation')">
            <template v-slot="scope">
              <el-button type="text"
                :disabled="(scope.row.maintStatus !== 1 && scope.row.maintStatus !== 2) || (scope.row.completionStatus === 3 && operationDisable) || scope.row.maintTypeName === '旧维保'"
                @click="handleEdit(scope.row)">
                {{ $t("common.edit") }}
              </el-button>
              <el-button type="text" class="operateDelBtn"
                :disabled="(scope.row.maintStatus !== 3 && scope.row.maintStatus !== 4) || (scope.row.completionStatus === 3 && operationDisable) || scope.row.maintTypeName === '旧维保'"
                @click="handleInvalid(scope.row)">
                {{ $l("maintWorkOrder.invalid", "作废") }}
              </el-button>
            </template>
          </el-table-column> -->
        </vm-table>
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label">
          {{ $l("maintWorkOrder.expired", "过期未保养") }}
          <el-badge :value="expiredTotal" :max="99" class="item"></el-badge>
        </span>
        <vm-table ref="expiredTable" v-loading="expiredLoading" :filter.sync="searchExpired" :multi-selection="true"
          :reserve-selection="true" url="maintenanceOrder" @row-click="handleRowClickExpired"
          @selection-change="handleSelectionChange" @clear="clearDateRange">
          <template slot="toolbar">
            <el-button v-if="maintAuth" class="dangerBtn" :disabled="deleteDisable" @click="handleDelete">
              {{ $t("common.delete") }}
            </el-button>
            <el-button @click="checkCompletionSituation">
              {{ $l("maintWorkOrder.completionSituation", "完成情况") }}
            </el-button>
            <el-button @click="checkMaintPlan">
              {{ $l("maintWorkOrder.maintPlan", "保养计划") }}
            </el-button>
            <el-button @click="handleClearExpiredSelection">
              {{ $l("maintWorkOrder.clearSelection", "清空多选") }}
            </el-button>
          </template>
          <template slot="adSearch">
            <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
              <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
            </vm-search>
            <vm-search :label="$l('maintWorkOrder.maintStatus', '状态')">
              <el-select v-model="search.maintStatus" :placeholder="$t('common.pleaseSelect')" clearable>
                <el-option v-for="item in stateOptions" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </vm-search>
            <vm-search :label="$l('maintWorkOrder.date', '计划保养日期')">
              <el-date-picker v-model="dateRange" value-format="yyyy-MM-dd" type="daterange" :unlink-panels="true"
                :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
                :end-placeholder="$t('common.endDate')" @change="handleDateChange">
              </el-date-picker>
            </vm-search>
          </template>
          <el-table-column prop="orderNum" :label="$l('maintWorkOrder.orderNum', '单号')" width="120" align="center">
            <template v-slot="scope">
              <router-link :to="'/newMaintWorkOrder/' + scope.row.id" target="_blank">{{ scope.row.orderNum }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column prop="elevatorNo" :label="$l('elevator.no', '电梯编号')" width="120" align="center">
            <template v-slot="scope">
              <router-link :to="'/elevator/' + scope.row.elevatorId" target="_blank">{{ scope.row.elevatorNo }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column prop="elevatorName" show-overflow-tooltip
            :label="$l('maintWorkOrder.elevatorName', '电梯名称')"></el-table-column>
          <el-table-column prop="useUnitName" show-overflow-tooltip
            :label="$l('elevator.useUnit', '使用单位')"></el-table-column>
          <el-table-column prop="maintComName" show-overflow-tooltip
            :label="$l('maintWorkOrder.company', '维保单位')"></el-table-column>
          <el-table-column prop="propertyComName" :label="$l('maintWorkOrder.propertyCom', '物业单位')"></el-table-column>
          <el-table-column prop="maintDate" :label="$l('maintWorkOrder.maintDate', '保养日期')" width="90px"
            align="center"></el-table-column>
          <el-table-column prop="maintTypeName" :label="$l('maintWorkOrder.maintType', '保养类型')"
            :width="$l('100px', '80px')" align="center"></el-table-column>
          <el-table-column prop="completionTime" :label="$l('maintWorkOrder.completionTime', '完成时间')" width="140"
            align="center"></el-table-column>
          <el-table-column prop="maintDuration" :label="$l('maintWorkOrder.maintDuration', '保养时长')"
            :width="$l('120px', '100')" align="center">
            <template v-slot="scope">
              <span v-if="scope.row.maintDuration !== null">
                {{ scope.row.maintDuration + $l(" minutes", " 分钟") }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="maintainerName1" :label="$l('elevator.maintWorker', '维保人员') + '1'"
            :width="$l('130', '100')" align="center"></el-table-column>
          <el-table-column prop="maintainerName2" :label="$l('elevator.maintWorker', '维保人员') + '2'"
            :width="$l('130', '100')" align="center"></el-table-column>
          <el-table-column prop="completionStatus" :label="$l('maintWorkOrder.completionStatus', '完成状态')"
            :width="$l('110', '100')" align="center">
            <template v-slot="scope">
              <el-tag v-if="scope.row.completionStatus === 1" type="success">
                {{ $l("maintWorkOrder.normal", "正常") }}
              </el-tag>
              <el-tag v-else-if="scope.row.completionStatus === 2">
                {{ $l("maintWorkOrder.advance", "提前") }}
              </el-tag>
              <el-tag v-else-if="scope.row.completionStatus === 3" type="danger">
                {{ $l("maintWorkOrder.overdue", "超期") }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="maintStatus" :label="$l('maintWorkOrder.maintStatus', '状态')" :width="$l('120', '80')"
            align="center">
            <template v-slot="scope">
              <el-tag v-if="scope.row.maintStatus === 0" type="danger"> {{ $l("maintWorkOrder.invalid", "作废") }}</el-tag>
              <el-tag v-else-if="scope.row.maintStatus === 1">
                {{ $l("maintWorkOrder.inPlan", "计划中") }}
              </el-tag>
              <el-tag v-else-if="scope.row.maintStatus === 2" type="warning">
                {{ $l("maintWorkOrder.notAccepted", "未接受") }}
              </el-tag>
              <el-tag v-else-if="scope.row.maintStatus === 3">
                {{ $l("maintWorkOrder.accepted", "已接受") }}
              </el-tag>
              <el-tag v-else-if="scope.row.maintStatus === 4">
                {{ $l("maintWorkOrder.signedIn", "已签到") }}
              </el-tag>
              <el-tag v-else-if="scope.row.maintStatus === 5" type="success">
                {{ $l("maintWorkOrder.completed", "已完成") }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="creator" :label="$l('maintWorkOrder.creator', '建单人')" width="80"
            align="center"></el-table-column>
          <el-table-column v-if="maintAuth" fixed="right" align="center" width="100" :label="$t('common.operation')">
            <template v-slot="scope">
              <el-button type="text"
                :disabled="(scope.row.maintStatus !== 1 && scope.row.maintStatus !== 2) || (scope.row.completionStatus === 3 && operationDisable) || scope.row.maintTypeName === '旧维保'"
                @click="handleEdit(scope.row)">
                {{ $t("common.edit") }}
              </el-button>
              <el-button type="text" class="operateDelBtn"
                :disabled="(scope.row.maintStatus !== 3 && scope.row.maintStatus !== 4) || (scope.row.completionStatus === 3 && operationDisable) || scope.row.maintTypeName === '旧维保'"
                @click="handleInvalid(scope.row)">
                {{ $l("maintWorkOrder.invalid", "作废") }}
              </el-button>
            </template>
          </el-table-column>
        </vm-table>
      </el-tab-pane>
    </el-tabs>
    <edit-dialog ref="editPage" @save-success="getList(-1)"></edit-dialog>
    <maint-plan-dialog ref="maintPlanPage"></maint-plan-dialog>
    <maint-invalid ref="maintInvalid" @invalid-success="getList(-1)"></maint-invalid>
    <maint-plan-edit ref="planEdit" @save-success="handlePlanSaveSuccess"></maint-plan-edit>
  </div>
</template>
<script>
import EditDialog from "./MaintWorkOrderEdit.vue";
import MaintPlanDialog from "./MaintPlan.vue";
import MaintInvalid from "@/views/newMaintWorkOrder/MaintInvalid";
import MaintPlanEdit from "@/views/newMaintWorkOrder/MaintPlanEdit";

const moduleName = "maintenanceOrder";

export default {
  components: { EditDialog, MaintPlanDialog, MaintInvalid, MaintPlanEdit },
  data() {
    return {
      maintAuth: this.$auth(320),
      loading: true,
      planLoading: true,
      expiredLoading: true,
      notAcceptLoading: true,
      operationDisable: false,
      deleteDisable: false,
      sendDisable: false,
      completionSituationDisable: false,
      maintPlanDisable: false,
      planAddDisable: false,
      planEditDisable: false,
      planInvalidDisable: false,
      activeName: this.$route.params.activeName == null ? "0" : this.$route.params.activeName,
      maintSetting: "",
      multipleSelection: [],
      multiplePlanSelection: [],
      availablePlan: [],
      dateRange: [],
      notAcceptTotal: 0,
      expiredTotal: 0,
      planTotal: 0,
      integrationList: [],
      search: {
        filter: "",
        integrationKey: "",
        completionStatus: "",
        maintStatus: "",
        startTime: "",
        endTime: "",
      },
      searchNotAccept: {
        filter: "",
        completionStatus: "",
        startTime: "",
        endTime: "",
        recentNotAccept: true,
      },
      searchExpired: {
        filter: "",
        maintStatus: "",
        startTime: "",
        endTime: "",
        expired: true,
      },
      searchPlan: {
        filter: "",
        maintPlan: "",
      },
      stateOptions: [
        { value: "0", label: this.$l("maintWorkOrder.invalid", "作废") },
        { value: "1", label: this.$l("maintWorkOrder.inPlan", "计划中") },
        { value: "2", label: this.$l("maintWorkOrder.notAccepted", "未接受") },
        { value: "3", label: this.$l("maintWorkOrder.accepted", "已接受") },
        { value: "4", label: this.$l("maintWorkOrder.signedIn", "已签到") },
        { value: "5", label: this.$l("maintWorkOrder.completed", "已完成") }],
      completionStateOptions: [
        { value: "1", label: this.$l("maintWorkOrder.normal", "正常") },
        { value: "2", label: this.$l("maintWorkOrder.advance", "提前") },
        { value: "3", label: this.$l("maintWorkOrder.overdue", "超期") }],
    };
  },
  mounted() {
    this.getList(1);
    this.getSetting();
    this.getIntegrationList();
  },
  methods: {
    getList(pageNum) {
      if (this.$refs.tabs.currentName === "0") {
        this.$refs.vmTable.getList(pageNum);
        this.loading = false;
      }
      if (this.$refs.tabs.currentName === "1") {
        this.$refs.maintPlan.getList(pageNum);
        this.planLoading = false;
      }
      if (this.$refs.tabs.currentName === "2") {
        this.$refs.notAcceptTable.getList(pageNum);
        this.notAcceptLoading = false;
      }
      if (this.$refs.tabs.currentName === "3") {
        this.$refs.expiredTable.getList(pageNum);
        this.expiredLoading = false;
      }
      this.getNotAcceptTotal();
      this.getExpiredTotal();
    },
    getIntegrationList() {
      this.$http.get("integrations/list").then(({ data }) => {
        this.integrationList = data;
      });
    },
    getSetting() {
      this.$http.get("maintSetting").then(res => {
        this.maintSetting = res.data;
        this.operationDisable = !this.maintSetting.isOperationOverdue;
      });
    },
    getNotAcceptTotal() {
      let param = {
        recentNotAccept: true,
      };
      this.$http.get(moduleName, param).then(res => {
        this.notAcceptTotal = res.data.total;
      });
    },
    getExpiredTotal() {
      let param = {
        expired: true,
      };
      this.$http.get(moduleName, param).then(res => {
        this.expiredTotal = res.data.total;
      });
    },
    handleTabClick(tab) {
      if (tab.index === "0") {
        this.handleClearSelection();
        this.$refs.vmTable.getList(1);
        this.loading = false;
      }
      if (tab.index === "1") {
        this.$refs.maintPlan.getList(1);
        this.planLoading = false;
      }
      if (tab.index === "2") {
        this.$refs.notAcceptTable.$refs.elTable.clearSelection();
        this.$refs.notAcceptTable.getList(1);
        this.notAcceptLoading = false;
      }
      if (tab.index === "3") {
        this.$refs.expiredTable.$refs.elTable.clearSelection();
        this.$refs.expiredTable.getList(1);
        this.expiredLoading = false;
      }
    },
    handleAdd() {
      this.$refs.editPage.open(0);
    },
    handleEdit(row) {
      this.$refs.editPage.open(row.id);
    },
    handleDelete() {
      if (this.multipleSelection.length > 1) {
        let deleteList = this.multipleSelection.map(item => item.id);
        this.$confirm(this.$l("maintWorkOrder.tip.confirmDelete", "确认删除已选记录吗，已删除的记录不可恢复！"), this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$http.post(moduleName + "/batchDelete", deleteList).then(() => {
            this.getList(-1);
            this.handleClearSelection();
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          }).catch(() => {
            this.$message.error(this.$t("common.tip.deleteError"));
          });
        });
      } else if (this.multipleSelection.length === 1) {
        this.$confirm(this.$t("common.delete") + " " + this.$l("maintWorkOrder.maintWorkOrder", "保养工单") + " " + this.multipleSelection[0].orderNum + "，" + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$api.deleteById(moduleName, this.multipleSelection[0].id).then(() => {
            this.getList(-1);
            this.handleClearSelection();
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          }).catch(err => {
            this.$message.error(this.$t("common.tip.deleteError") + ", " + err.response.data.message);
          });
        });
      } else {
        this.$message.error(this.$l("maintWorkOrder.tip.emptyMultipleRow", "请选择至少一条记录") + "!");
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.deleteDisable = false;
      this.sendDisable = false;
      this.completionSituationDisable = false;
      this.maintPlanDisable = false;
      this.multipleSelection.forEach(item => {
        if (item.maintStatus !== 1 && item.maintStatus !== 2) {
          this.deleteDisable = true;
        }
        if (item.maintStatus !== 1) {
          this.sendDisable = true;
        }
        if (item.completionStatus === 3 && this.operationDisable) {
          this.sendDisable = true;
        }
      });
      if (this.multipleSelection.length > 1) {
        this.completionSituationDisable = true;
        this.maintPlanDisable = true;
      }
    },
    handleSelectionChangePlan(val) {
      this.multiplePlanSelection = val;
      this.availablePlan = val.filter(item => {
        return !item.maintPlan;
      });
      this.planAddDisable = false;
      this.planEditDisable = false;
      this.planInvalidDisable = false;
      this.multiplePlanSelection.forEach(item => {
        if (item.maintPlan) {
          this.planAddDisable = true;
        } else {
          this.planEditDisable = true;
          this.planInvalidDisable = true;
        }
      });
      if (this.multiplePlanSelection.length > 1) {
        this.planEditDisable = true;
        this.planInvalidDisable = true;
      }
    },
    handleRowClick(row, column) {
      if (column && column.label === this.$t("common.operation")) {
        return;
      }
      this.$refs.vmTable.$refs.elTable.toggleRowSelection(row);
    },
    handleRowClickNotAccept(row, column) {
      if (column && column.label === this.$t("common.operation")) {
        return;
      }
      this.$refs.notAcceptTable.$refs.elTable.toggleRowSelection(row);
    },
    handleRowClickExpired(row, column) {
      if (column && column.label === this.$t("common.operation")) {
        return;
      }
      this.$refs.expiredTable.$refs.elTable.toggleRowSelection(row);
    },
    handleRowClickPlan(row) {
      this.$refs.maintPlan.$refs.elTable.toggleRowSelection(row);
    },
    handleDateChange(dateRange) {
      if (dateRange != null) {
        this.search.startTime = dateRange[0];
        this.search.endTime = dateRange[1];
      } else {
        this.search.startTime = "";
        this.search.endTime = "";
      }
    },
    handleInvalid(row) {
      this.$refs.maintInvalid.open(row.id);
    },
    handleAddPlan() {
      if (this.availablePlan.length > 0) {
        this.$refs.planEdit.open(this.availablePlan, false);
      } else {
        this.$message.error(this.$l("maintWorkOrder.tip.emptyMultipleRow", "请选择至少一条记录") + "!");
      }
    },
    handleEditPlan() {
      if (this.multiplePlanSelection.length > 0) {
        this.$refs.planEdit.open(this.multiplePlanSelection, true);
      } else {
        this.$message.error(this.$l("maintWorkOrder.tip.emptySingleRow", "请选择一条记录") + "!");
      }
    },
    handlePlanSaveSuccess() {
      this.handlePlanClear();
      this.getList(-1);
    },
    handlePlanInvalid() {
      if (this.multiplePlanSelection.length > 0) {
        this.$confirm(this.$l("maintWorkOrder.tip.confirmInvalid", "确认作废已选电梯保养计划吗，已作废的保养计划不可恢复！"), this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          let params = {
            elevatorId: this.multiplePlanSelection[0].id,
          };
          this.$http.post("maintPlan/batchInvalid", null, params).then(() => {
            this.getList(-1);
            this.handlePlanClear();
            this.$message.success(this.$l("maintWorkOrder.tip.invalidSuccess", "作废成功"));
          }).catch(() => {
            this.$message.error(this.$l("maintWorkOrder.tip.invalidError", "作废失败"));
          });
        });
      } else {
        this.$message.error(this.$l("maintWorkOrder.tip.emptySingleRow", "请选择一条记录") + "!");
      }
    },
    handleTagClose(tag) {
      let selectedId = this.$refs.maintPlan.tableData.map(data => data.id);
      this.$refs.maintPlan.tableData.forEach(row => {
        if (row.id === tag.id) {
          this.$refs.maintPlan.$refs.elTable.toggleRowSelection(row);
        } else {
          this.multiplePlanSelection.forEach(item => {
            if (item.id === tag.id && !selectedId.includes(item.id)) {
              this.$refs.maintPlan.$refs.elTable.toggleRowSelection(item);
            }
          });
        }
      });
    },
    handlePlanClear() {
      this.$refs.maintPlan.$refs.elTable.clearSelection();
    },
    handleClearSelection() {
      this.$refs.vmTable.$refs.elTable.clearSelection();
    },
    handleClearUnAcceptSelection() {
      this.$refs.notAcceptTable.$refs.elTable.clearSelection();
    },
    handleClearExpiredSelection() {
      this.$refs.expiredTable.$refs.elTable.clearSelection();
    },
    clearDateRange() {
      this.dateRange = [];
    },
    sendWorkOrder() {
      if (this.multipleSelection.length > 0) {
        this.$confirm(this.$l("maintWorkOrder.tip.confirmSend", "确认发送已选工单？"), this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$http.post(moduleName + "/batchSend", this.multipleSelection).then(() => {
            this.handleClearSelection();
            this.getList(-1);
            this.$message.success(this.$l("maintWorkOrder.tip.sendSuccess", "发送成功"));
          }).catch(() => {
            this.$message.error(this.$l("maintWorkOrder.tip.sendError", "发送失败"));
          });
        });
      } else {
        this.$message.error(this.$l("maintWorkOrder.tip.emptyMultipleRow", "请选择至少一条记录") + "!");
      }
    },
    checkCompletionSituation() {
      if (this.multipleSelection.length > 0) {
        let route = this.$router.resolve(
          {
            path: "/newMaintWorkOrder/:maintWorkOrderId",
            name: "newMaintWorkOrderQuery",
            params: { maintWorkOrderId: this.multipleSelection[0].id },
          });
        window.open(route.href, "_blank");
      } else {
        this.$message.error(this.$l("maintWorkOrder.tip.emptySingleRow", "请选择一条记录") + "!");
      }
    },
    checkMaintPlan() {
      if (this.multipleSelection.length > 0) {
        this.$refs.maintPlanPage.open(this.multipleSelection[0].elevatorId);
      } else {
        this.$message.error(this.$l("maintWorkOrder.tip.emptySingleRow", "请选择一条记录") + "!");
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
